<template>
  <div id="app">
    <router-view style="margin-right: 300px;" v-bind:handleflag="handleflag" @changeActive="changeActiveHand" />
    <nav>
      <router-link :to="item.link" v-for="(item,index) in gzArr" :key="index">{{ item.name }}
      </router-link>
      <Form ref="formCustom" :model="searchParams" :label-width="80" :label-colon="true" style="width: 90%;">
        <FormItem label="开始期数" prop="startIssue">
          <Input v-model="searchParams.startIssue" placeholder="Enter something..." />
        </FormItem>
        <FormItem label="结束期数" prop="endIssue">
          <Input v-model="searchParams.endIssue" placeholder="Enter something..." />
        </FormItem>
        <FormItem>
          <Button type="primary" @click="handleSubmit">搜索</Button>
          <Button @click="handleReset('formCustom')" style="margin-left: 8px">重置</Button>
        </FormItem>
      </Form>
      <div v-show="tjNum.length">推荐号码：{{ tjNum.join("-") }}</div>
      <div v-show="prizeData.length">下期开奖：{{ prizeData[prizeData.length-1].join("-") }}</div>
    </nav>
    <Spin size="large" fix v-if="spinShow"></Spin>
  </div>
</template>
<script>
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      prizeData: [],
      handleflag: 0,
      spinShow: false,
      result: {},
      tjNum: [],
      tjgl: [],
      searchParams: { startIssue: "2023057", endIssue: "2023255" },
      gzArr: [{ name: "连续出现及连续不出现规则", link: "/" }, { name: "规则2结果", link: "/result" }, { name: "规则2训练", link: "/train" }, {
        name: "规则4",
        link: "/about"
      }, { name: "规则5", link: "/about" }]
    };
  },
  mounted() {
    this.dataComputeChance();
    this.dataListPrizeDates();
  },
  methods: {
    dataListPrizeDates() {
      this.$api.dataListPrizeDates({ ...this.searchParams, yesDate: Number(this.searchParams.endIssue) + 1 }).then(res => {
        if (200 == res.data.code) {
          this.prizeData = [];
          res.data.data.forEach(e => {
            let { num_one, num_two, num_three, num_four, num_five } = { ...e };
            this.prizeData[e.issue] = [num_one, num_two, num_three, num_four, num_five];
          });
        }
      });
    },
    dataComputeChance() {
      this.spinShow = true;
      this.$api.dataComputeChance(this.searchParams).then(res => {
        if (200 == res.data.code) {
          this.spinShow = false;
          this.tjNum = [];
          this.tjgl = [];
          Object.keys(res.data.data).forEach(e => {
            let edata = res.data.data[e];
            let numIssue = this.searchParams.endIssue - edata.yesDate;
            if (edata.no.length <= (this.searchParams.endIssue - edata.yesDate + 1)) {
              res.data.data[e].noPercent = "0.01%";
            } else {
              res.data.data[e].noPercent = edata.no[edata.no.length - (this.searchParams.endIssue - edata.yesDate + 1)].percent;
            }
            if (edata.yes.length <= (this.searchParams.endIssue - edata.noDate + 1)) {
              res.data.data[e].yesPercent = "0.01%";
            } else {
              res.data.data[e].yesPercent = edata.yes[edata.yes.length - (this.searchParams.endIssue - edata.noDate + 1)].percent;
            }
            let isTj = (Number(res.data.data[e].yesPercent.substring(0, res.data.data[e].yesPercent.length - 1)) > Number(res.data.data[e].noPercent.substring(0, res.data.data[e].noPercent.length - 1))) && ("0.01%" !== res.data.data[e].noPercent);
            if (isTj) {
              this.tjNum.push(e);
            }
            this.tjgl.push({ val: e, ...edata, isTj });
            res.data.data[e].yesMsg = `下一轮出现${e}的概率为${res.data.data[e].yesPercent}`;
            res.data.data[e].noMsg = `下一轮不出现${e}的概率为${res.data.data[e].noPercent}`;
          });
          this.result = res.data.data;
        }
      });
    },
    changeActiveHand(e) {
      this.tjNum = e.data;
      this.tjgl = e.tjgl;
    },
    handleSubmit() {
      this.handleflag = Date.now();
      this.dataComputeChance();
    }
  }
};
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  position: fixed;
  right: 0;
  top: 20px;
  text-align: left;
  flex-direction: column;
  align-content: flex-start;

  a {
    margin: 0.5em 1em;
    font-weight: bold;
    color: #2c3e50;
    display: block;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
