import axios from "@/libs/api.request";
import config from "@/config";
import md5 from "js-md5";
import { Decrypt, Encrypt } from "@/libs/aes128";

let api = "swxwapi";
export default {
  dataComputeChance(params = {}) {
    return axios.request({
      url: api + "/data/computeChance",
      params,
      method: "post"
    });
  },
  dataListPrizeDates(params = {}) {
    return axios.request({
      url: api + "/data/listPrizeDates",
      params,
      method: "post"
    });
  }
};
