import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ViewUI from "view-design";
import "view-design/dist/styles/iview.css";
import api from '@/api/api'
Vue.config.productionTip = false;
Vue.use(ViewUI);
// Load the full build.
var _ = require('lodash');
Vue.prototype.$_ = _;
Vue.prototype.$api = api;
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
