import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      title: "规则1"
    },
    component: HomeView
  },
  {
    path: "/result",
    name: "result",
    meta: {
      title: "结果"
    },
    component: () =>
      import("../views/result.vue")
  },
  {
    path: "/about",
    name: "about",
    meta: {
      title: "规则2"
    },
//    component: HomeView,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import("../views/AboutView.vue")
  },
  {
    path: "/train",
    name: "train",
    meta: {
      title: "规则2训练"
    },
//    component: HomeView,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import("../views/train.vue")
  }
];

const router = new VueRouter({
  mode: "hash",
  routes
});

export default router;
