<template>
  <div>
    <!--    <h1>{{ $route.meta.title }}</h1>-->
    <Tabs v-model="flag">
      <TabPane :label="'数字'+key" :name="'a'+key" v-for="(item,key,index) in $parent.result" :key="key"
               :icon="$parent.tjNum.includes(key)?'md-checkmark':''">
        <Card :bordered="false" :title="'数字'+key+'不可能出现的概率 【'+item.noMsg+'】'">
          <Table :columns="[...columns,{title: '连续不出现概率值',key: 'percent'}]" :data="item.no" height="450"></Table>
        </Card>
        <Card :bordered="false" :title="'数字'+key+'可能出现的概率 【'+item.yesMsg+'】'">
          <Table :columns="[...columns,{title: '连续出现概率值',key: 'percent'}]" :data="item.yes" height="280"></Table>
        </Card>
      </TabPane>
    </Tabs>
  </div>
</template>

<script>

export default {
  name: "HomeView",
  components: {},
  props: ["handleflag"],
  data() {
    return {
      flag: "a1",
      columns: [{ title: "连续峰值", key: "val" }, { title: "出现次数", key: "count" }, { title: "#衍生次数", key: "deriveCount" }],
      data: [],

    };
  },
  watch: {
    // 监听父组件的msg的变化
    handleflag() {
//      this.dataComputeChance();
    }
  },
  mounted() {
//    this.fetchTotal();
  },
  methods: {
    fetchTotal(index = 1) {
      let arr = [0, 24, 13, 3, 1, 1];
      let total = 0;
      arr.forEach((e, i) => {
        if (i >= index && i < arr.length) {
          let dt = 0;
          if (index == i) {
            total = e;
          } else {
            dt = arr[i] * (i + 1 - index);
            total += dt;
          }
          console.warn({ total, i, index, leng: arr.length - 1, dt });
        }
      });
      console.log(total);
    },

  },
  beforeDestroy() {
  }
};
</script>
<style lang="less">

</style>
